/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "./src/styles/global.css"

// export const onServiceWorkerUpdateReady = () => {
//   window.location.reload(true)
// }

// export const onServiceWorkerInstalled = () => {
//   window.location.reload(true)
// }

export const onClientEntry = () => {
  let verNpoSvl = 2

  if (Number(localStorage.getItem("ver_npo_svl")) != verNpoSvl) {
    localStorage.setItem("ver_npo_svl", verNpoSvl.toString())

    setTimeout(() => {
      window.location.reload()
    }, 500)
  }
}

// export const onClientEntry = () => {
//   window.onload = () => {
//     if ("serviceWorker" in navigator) {
//       navigator.serviceWorker.getRegistrations().then(function (registrations) {
//         for (let registration of registrations) {
//           registration.unregister()
//         }
//       })
//     }
//   }
// }
